<template>
  <div>
    <b-button
      v-if="user.user_status === 3 || user.user_status === 2"
      class="mb-1"
      variant="outline-primary"
      @click="openSendInviteModal"
    >
      <feather-icon
        icon="MailIcon"
        class="mr-50"
      />
      <span class="align-middle">Send Invite</span>
    </b-button>
    <b-button
      v-if="user.user_status === 3"
      class="mb-1 text-capitalize"
      variant="outline-primary"
      @click="notEligibleForFreeTrial"
    >
      <feather-icon
        icon="UserXIcon"
        class="mr-50"
      />
      <span class="align-middle">Not eligible for free trial</span>
    </b-button>
    <b-button
      class="mb-1"
      variant="outline-primary"
      @click="openChangeStatusModal"
    >
      <feather-icon
        icon="Edit2Icon"
        class="mr-50"
      />
      <span class="align-middle">Change Status/User Role</span>
    </b-button>
    <b-button
      class="mb-1"
      variant="outline-primary"
      @click="openActionsModal"
    >
      <feather-icon
        icon="ToolIcon"
        class="mr-50"
      />
      <span class="align-middle">Actions</span>
    </b-button>
    <b-button
      v-if="user.user_status === 7"
      class="mb-1"
      variant="outline-primary"
      @click="sendSubExpMail"
    >
      <feather-icon
        icon="MailIcon"
        class="mr-50"
      />
      <span class="align-middle">Send Sub. Expired Mail</span>
    </b-button>
    <b-button
      v-if="user.user_status === 6"
      class="mb-1"
      variant="outline-primary"
      @click="sendSubCanceledMail"
    >
      <feather-icon
        icon="MailIcon"
        class="mr-50"
      />
      <span class="align-middle">Send Sub. Canceled Mail</span>
    </b-button>
    <b-button
      v-if="user.user_status === 4"
      v-b-modal.change-trial-period-modal
      class="mb-1"
      variant="outline-primary"
    >
      <feather-icon
        icon="CalendarIcon"
        class="mr-50"
      />
      <span class="align-middle">Change Trial Period</span>
    </b-button>
    <b-button
      v-if="user.user_status === 8"
      v-b-modal.change-backup-period-modal
      class="mb-1"
      variant="outline-primary"
    >
      <feather-icon
        icon="CalendarIcon"
        class="mr-50"
      />
      <span class="align-middle">Change Backup Period</span>
    </b-button>
    <b-button
      v-b-modal.add-discount-modal
      class="mb-1"
      variant="outline-primary"
    >
      <feather-icon
        icon="PercentIcon"
        class="mr-50"
      />
      <span class="align-middle">Add Discount</span>
    </b-button>
    <b-button
      v-if="user.user_status === 1 || user.active == 0"
      v-b-modal.activation-email-modal
      class="mb-1"
      variant="outline-primary"
    >
      <feather-icon
        icon="UserCheckIcon"
        class="mr-50"
      />
      <span class="align-middle">Activation Email</span>
    </b-button>
    <b-button
      v-b-modal.add-referral-modal
      class="mb-1"
      variant="outline-primary"
      @click="loadReferrals"
    >
      <feather-icon
        icon="UserPlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Referral</span>
    </b-button>
    <b-button
      v-b-modal.change-billing-period-modal
      class="mb-1 btn"
      variant="outline-primary"
    >
      <feather-icon
        icon="BoldIcon"
        class="mr-50"
      />
      <span class="align-middle">Change Billing Cycle</span>
    </b-button>
    <b-button
      v-if="(user.user_status === 2 || user.user_status === 3 || user.user_status === 5) && settings.network_type === 'domain'"
      v-b-modal.set-ip-modal
      class="mb-1"
      variant="outline-primary"
    >
      <feather-icon
        icon="GlobeIcon"
        class="mr-50"
      />
      <span class="align-middle">Set IPs</span>
    </b-button>
    <b-button
      v-if="user.user_status >= 4 || user.user_status === 0"
      v-b-modal.pro-forma-modal
      class="mb-1"
      variant="outline-primary"
    >
      <feather-icon
        icon="FileTextIcon"
        class="mr-50"
      />
      <span class="align-middle">Pro Forma Invoices</span>
    </b-button>

    <!-- ** Modals ** -->

    <b-modal
      id="actions-modal"
      ref="actions"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Close"
      centered
      title="Actions"
      @ok="closeActions"
    >
      <b-form-group class="text-center">
        <b-button
          v-if="user.user_status >= 4 && showUserSetting"
          v-b-modal.expire-tag-modal
          class="w-75"
          variant="outline-primary"
        >
          <feather-icon
            icon="TagIcon"
            class="mr-50"
          />
          <span>Expired Tag</span>
        </b-button>
      </b-form-group>
      <b-form-group class="text-center">
        <b-button
          class="w-75"
          variant="danger"
          @click="deleteUser(user.user_id)"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-50"
          />
          <span>Delete User</span>
        </b-button>
      </b-form-group>
    </b-modal>
    <!-- Change Status -->
    <b-modal
      id="change-status-modal"
      ref="changeStatus"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Update"
      centered
      title="Change Status/User Role"
      @ok="updateStatus"
    >
      <b-form>
        <b-form-group
          label="Status:"
          label-for="action-status"
        >
          <b-form-select
            id="action-status"
            v-model="status"
          >

            <b-form-select-option-group label="User Roles">
              <b-form-select-option
                value="56"
                style="color: #20adbd"
              >
                Affiliate
              </b-form-select-option>
              <b-form-select-option
                value="9"
                style="color:red"
              >
                Admin
              </b-form-select-option>
            </b-form-select-option-group>

            <b-form-select-option-group label="User Status">
              <b-form-select-option value="1">
                Lead
              </b-form-select-option>
              <b-form-select-option value="2">
                Registered
              </b-form-select-option>
              <b-form-select-option value="3">
                Pending
              </b-form-select-option>
              <b-form-select-option value="4">
                Trial
              </b-form-select-option>
              <b-form-select-option value="0">
                Pending Payment
              </b-form-select-option>
              <b-form-select-option value="5">
                Subscribed
              </b-form-select-option>
              <b-form-select-option value="8">
                Backup
              </b-form-select-option>
              <b-form-select-option
                value="7"
                style="color:darkorange;"
              >
                Expired
              </b-form-select-option>
              <b-form-select-option
                value="10"
                style="color:darkorange;"
              >
                Suspended
              </b-form-select-option>
              <b-form-select-option value="6">
                Canceled
              </b-form-select-option>
            </b-form-select-option-group>

          </b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Change Status -->
    <b-modal
      id="send-invite-modal"
      ref="sendInvite"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Send"
      centered
      title="Send Invite Email"
      @ok="sendInvite"
    >
      <b-form>
        <b-form-group
          label="Language:"
          label-for="action-status"
        >
          <b-form-select
            id="action-status"
            v-model="languageInvite"
            :options="languageInviteOptions"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Change Trial Period -->
    <b-modal
      id="change-trial-period-modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Update"
      centered
      title="Change Trial Period"
      @ok="changeTrialPeriod"
    >
      <b-form>
        <b-form-group
          label="Trial Start Date:"
          label-for="start-date"
        >
          <date-picker
            v-model="trial.startDate"
            format="YYYY-MM-DD"
            type="date"
            value-type="format"
          />
        </b-form-group>
        <b-form-group
          label="Trial End Date:"
          label-for="end-date"
        >
          <date-picker
            v-model="trial.endDate"
            format="YYYY-MM-DD"
            type="date"
            value-type="format"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- CHANGE BACKUP PERIOD -->
    <b-modal
      id="change-backup-period-modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Update"
      centered
      title="Change Backup Period"
      @ok="changeBackupPeriod"
    >
      <b-form>
        <b-form-group
          label="Backup Start Date:"
          label-for="start-date"
        >
          <date-picker
            v-model="backup.startDate"
            format="YYYY-MM-DD"
            type="date"
            value-type="format"
          />
        </b-form-group>
        <b-form-group
          label="Backup End Date:"
          label-for="end-date"
        >
          <date-picker
            v-model="backup.endDate"
            format="YYYY-MM-DD"
            type="date"
            value-type="format"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- @@@@@@@@@@@@@ CHANGE BILLING CYCLE !!!!!!!!!!! -->
    <b-modal
      id="change-billing-period-modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Update"
      centered
      title="Change Billing Period"
      @ok="changeBillingCycle"
    >
      <b-form>
        <b-form-group
          label="Current Billing Cycle:"
          label-for="billing-cycle"
        >
          <h6>
            {{ billing.pay_months }} Month(s)
          </h6>
        </b-form-group>
        <b-form-group
          label="Update Billing Cycle:"
          label-for="end-date"
        >
          <b-form-select
            v-model="selectedOption"
            @change="setBilling($event)"
          >
            <option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
            >
              <p style="padding:4px;margin:10px;">
                {{ option.text }}
              </p>
            </option>
          </b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Add Discount -->
    <b-modal
      id="add-discount-modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Update"
      centered
      title="Add Discount"
      @ok="addDiscount"
    >
      <b-form>
        <b-form-group
          label="Amount"
          label-for="amount"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              EUR
            </b-input-group-prepend>
            <b-form-input
              id="amount"
              v-model="discount.amount"
              placeholder="0.00"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Duration in Months"
          label-for="month"
        >
          <b-form-spinbutton
            id="month"
            v-model="discount.duration"
            min="1"
            max="100"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Activation Email -->
    <b-modal
      id="activation-email-modal"
      ref="activationEmail"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Activation Email"
    >
      <div class="text-center">
        <b-button
          variant="primary"
          class="activation-btn"
          :disabled="sendingConfirmEmail"
          @click="confirmEmail"
        >
          <span v-if="sendingConfirmEmail">
            <b-spinner
              small
              type="grow"
            />
            Confirming email...
          </span>
          <span v-else>Confirm Email</span>
        </b-button><br>
        <b-button
          variant="primary"
          class="activation-btn mt-1 mb-1"
          :disabled="sendingActivationEmail"
          @click="resendActivationEmail"
        >
          <span v-if="sendingActivationEmail">
            <b-spinner
              small
              type="grow"
            />
            Resending activation email...
          </span>
          <span v-else>Resend Activation Email</span>

        </b-button>
      </div>
    </b-modal>

    <!-- Add Referral -->
    <b-modal
      id="add-referral-modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Apply"
      centered
      title="Add Referral"
      no-close-on-backdrop
      @ok="addReferral"
    >
      <b-form>
        <b-form-group
          label="Action:"
          label-for="referral-type"
        >
          <b-form-select
            id="referral-type"
            v-model="referral.type"
            :options="[{ value: 'referred_by', text: 'Referred' },{ value: 'cpa_referred_by', text: 'CPA referred' }]"
          />
        </b-form-group>
        <b-form-group
          label="Referred By:"
          label-for="referred-by"
        >
          <v-select
            id="referred-by"
            v-model="referral.by"
            :options="allUser"
            label="email"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Set IP's -->
    <b-modal
      id="set-ip-modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Submit"
      centered
      title="Set IP"
      @ok="setIP"
    >
      <b-form>
        <b-form-group
          label="IPv4:"
          label-for="ipv4"
        >
          <b-form-input
            id="ipv4"
            v-model="ip.v4"
          />
        </b-form-group>
        <b-form-group
          label="IPv6"
          label-for="ipv6"
        >
          <b-form-input
            id="ipv6"
            v-model="ip.v6"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Expire Tag Request -->
    <b-modal
      id="expire-tag-modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Send Request"
      centered
      title="Expire Tag Request"
      @ok="sendExpireRequest"
    >
      <b-form>
        <b-form-group
          label="Action:"
          label-for="expire-tag"
        >
          <b-form-select
            id="expire-tag"
            v-model="expireTagStatus"
            :options="[{ value: '-1', text: 'Enable expired tag in user platform' },
                       { value: '1', text: 'Remove expired tag in user platform' }]"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Pro Forma Invoices -->
    <b-modal
      id="pro-forma-modal"
      ref="proFormaModal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Pro Forma Invoices"
    >
      <div class="text-center">
        <b-button
          variant="primary"
          class="activation-btn"
          title="Pro Forma Invoice, as generated by a canceled user. Start date is today."
          :disabled="sendingGenerateRenewPFI"
          @click="generateRenewPFI"
        >
          <span v-if="sendingGenerateRenewPFI">
            <b-spinner
              small
              type="grow"
            />
            Generate Renew PFI
          </span>
          <span v-else>Generate Renew PFI</span>
        </b-button><br>
        <b-button
          variant="primary"
          class="activation-btn mt-1 mb-1"
          title="Pro Forma Invoice, as generated normally by the server every cycle."
          :disabled="sendingGenerateRecurrentPFI"
          @click="generateRecurrentPFI"
        >
          <span v-if="sendingGenerateRecurrentPFI">
            <b-spinner
              small
              type="grow"
            />
            Generate Recurrent PFI
          </span>
          <span v-else>Generate Recurrent PFI</span>
        </b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>

import {
  BButton, BModal, BFormSelect, BFormSelectOptionGroup, BFormSelectOption, BForm, BFormGroup, BFormInput, BFormSpinbutton,
  BInputGroup, BSpinner,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BModal,
    BFormSelect,
    BForm,
    BFormGroup,
    DatePicker,
    BFormInput,
    BFormSpinbutton,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    BSpinner,
    BFormSelectOption,
    BFormSelectOptionGroup,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    settings: {
      type: Object,
      default: null,
    },
    action: {
      type: Object,
      default: null,
    },
    showUserSetting: {
      type: Boolean,
      default: false,
    },
    billing: {
      type: Object,
      default: null,
    },
    script: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      status: null,
      expireTagStatus: '-1',
      languageInvite: 'en',
      languageInviteOptions: [
        { value: 'en', text: 'English' },
        { value: 'it', text: 'Italian' },
      ],
      trial: {
        startDate: null,
        endDate: null,
      },
      backup: {
        startDate: null,
        endDate: null,
      },
      discount: {
        amount: null,
        duration: 0,
      },
      referral: {
        type: 'referred_by',
        by: { value: null, email: 'Nobody' },
      },
      userOption: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
      ip: {
        v4: null,
        v6: null,
      },
      allUser: [],
      sendingConfirmEmail: false,
      sendingActivationEmail: false,
      sendingGenerateRenewPFI: false,
      sendingGenerateRecurrentPFI: false,
      options: [
        { value: null, text: 'Select Cycle' },
        { value: 1, text: 'Monthly' },
        { value: 3, text: 'Every Three Months' },
        { value: 6, text: 'Every Six Months' },
        { value: 12, text: 'Yearly' },
      ],
      selectedOption: null,
    }
  },
  created() {
    if (this.script) {
      this.ip.v4 = this.script.ipv4
      this.ip.v6 = this.script.ipv6
    }
    this.status = this.user.user_status
    this.trial.startDate = this.user.trial_start ? this.user.trial_start : null
    this.trial.endDate = this.user.trial_end ? this.user.trial_end : null
    this.backup.startDate = this.user.backup_start ? this.user.backup_start : null
    this.backup.endDate = this.user.backup_end ? this.user.backup_end : null

    this.allUser = this.action.referral
    if (this.user.referredBy) {
      this.referral.type = 'referred_by'
      this.referral.by.value = this.user.referredBy.uid
      this.referral.by.email = this.user.referredBy.email
    } else if (this.user.cpaReferredBy) {
      this.referral.type = 'cpa_referred_by'
      this.referral.by.value = this.user.cpaReferredBy.uid
      this.referral.by.email = this.user.cpaReferredBy.email
    }
    this.allUser.unshift({ value: null, email: 'Nobody' })
    this.removeUser(this.allUser, this.user.email)
  },
  methods: {
    closeActions() {
      this.$refs.actions.hide()
    },
    loadReferrals() {
      axios
        .get('api/users/load_referrals')
        .then(res => {
          const referrals_arr = res.data.action.referral
          // push all users to allUser array
          this.allUser.push(...referrals_arr)
          // remove duplicate users
          this.allUser = this.allUser.filter((item, index, self) => index === self.findIndex(t => (
            t.value === item.value
          ))) // remove duplicate users
          // remove first null value
          this.allUser.shift()
          if (this.user.referredBy) {
            this.referral.type = 'referred_by'
            this.referral.by.value = this.user.referredBy.uid
            this.referral.by.email = this.user.referredBy.email
          } else if (this.user.cpaReferredBy) {
            this.referral.type = 'cpa_referred_by'
            this.referral.by.value = this.user.cpaReferredBy.uid
            this.referral.by.email = this.user.cpaReferredBy.email
          }
          this.allUser.unshift({ value: null, email: 'Nobody' })
          this.removeUser(this.allUser, this.user.email)
        })
        .catch(err => {
          console.log(err)
        })
    },
    setBilling(e) {
      this.selectedOption = e
    },
    changeBillingCycle() {
      if (this.selectedOption === null) {
        this.notify({
          text: 'Please select a billing cycle!',
          variant: 'danger',
        })
        return
      }
      const payload = {
        // eslint-disable-next-line radix
        uid: parseInt(this.$route.params.id),
        billing_cycle: this.selectedOption,
      }
      axios
        .post('api/users/update_billing_cycle', payload)
        // if res is 200 then show success message
        .then(res => {
          if (res.status === 200) {
            this.notify({
              text: 'Billing Cycle Updated!',
              variant: 'success',
            })
          } else {
            this.notify({
              text: 'Something went wrong!',
              variant: 'danger',
            })
          }
        })
        // refresh the page in 5 seconds
        .then(() => {
          setTimeout(() => {
            this.$router.go()
          }, 5000)
        })
    },
    removeUser(allUser, email) {
      let x = 0
      for (x = 0; x < allUser.length; x += 1) {
        if (allUser[x].email === email) {
          break
        }
      }
      if (x > -1) {
        allUser.splice(x, 1)
      }
    },
    openSendInviteModal() {
      this.$refs.sendInvite.show()
    },
    openChangeStatusModal() {
      this.$refs.changeStatus.show()
    },
    openActionsModal() {
      this.$refs.actions.show()
    },
    sendSubExpMail() {
      this.$store.dispatch('users/sendSubExpMail', this.user.user_id)
        .then(() => {
          this.notify({
            text: 'Email Sent!',
            variant: 'success',
          })
        })
        .catch(err => {
          console.log(err)
          this.notify({
            text: 'Email Not Sent!',
            variant: 'danger',
          })
        })
    },
    sendSubCanceledMail() {
      this.$store.dispatch('users/sendSubCanceledMail', this.user.user_id)
        .then(() => {
          this.notify({
            text: 'Email Sent!',
            variant: 'success',
          })
        })
        .catch(err => {
          console.log(err)
          this.notify({
            text: 'Email Not Sent!',
            variant: 'danger',
          })
        })
    },
    sendInvite() {
      const payload = {
        id: this.user.user_id,
        status: this.status,
        language: this.languageInvite,
      }
      this.$store.dispatch('users/sendUserInvite', payload)
        .then(() => {
          this.notify({
            text: 'Email Invite Sent!',
            variant: 'success',
          })
        })
        .catch(err => {
          console.log(err)
          this.notify({
            text: 'Email Not Sent!',
            variant: 'danger',
          })
        })
    },
    updateStatus() {
      this.$store.dispatch('users/updateUserStatus', { id: this.user.user_id, status: this.status }).then(res => {
        this.$store.commit('users/UPDATE_USER', res)
        this.$emit('updateUser', res)
        this.notify({
          text: 'Status Updated Successfully!',
          variant: 'success',
        })

        if (res.domain || res.subdomain) {
          let status = 1
          let domain = ''
          let url = ''

          if (res.user_status === 6 || res.user_status === 7) status = -1

          if (res.network_type === 'domain') domain = `${res.sub_admin}.${res.domain}`
          else domain = `${res.subdomain}.trafficmanager.com`

          url = `https://${domain}/api/admin/tmSubscriptionExpired/${status}`

          this.$http.get(url)
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    deleteUser(id) {
      this.confirmationAlert('Are you sure you want to delete this user?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/deleteUser', id).then(() => {
              this.$router.push('/users/all')
              this.notify({
                text: 'Deleted Successfully!',
                variant: 'success',
              })
            })
          }
        })
    },
    notEligibleForFreeTrial() {
      this.confirmationAlert('Are you sure you want to tag this user not eligible for free trial?')
        .then(res => {
          if (res.isConfirmed) {
            this.$store.dispatch('users/sendPendingPaymentMail', this.user.user_id)
              .then(result => {
                this.$store.commit('users/UPDATE_USER', result.user)
                this.$emit('updateUser', result.user)
                this.notify({
                  text: 'User updated and email sent!',
                  variant: 'success',
                })
                this.$emit('refreshUserInvoice', true)
              })
              .catch(err => {
                console.log(err)
                this.notify({
                  text: 'User not updated successfully!',
                  variant: 'danger',
                })
              })
          }
        })
    },
    changeTrialPeriod() {
      const payload = {
        id: this.user.user_id,
        trialStart: this.trial.startDate,
        trialEnd: this.trial.endDate,
      }

      this.$store.dispatch('users/updateTrialPeriod', payload)
        .then(result => {
          this.$store.commit('users/UPDATE_USER', result.user)
          this.notify({
            text: 'Trial period has been updated successfully!',
            variant: 'success',
          })
        })
        .catch(err => {
          console.log(err)
          this.notify({
            text: 'Trial period has not been updated!',
            variant: 'danger',
          })
        })
    },
    changeBackupPeriod() {
      const payload = {
        id: this.user.user_id,
        backup_start: this.backup.startDate,
        backup_end: this.backup.endDate,
      }

      axios.patch('/api/users/update_backup_period', payload)
        .then(result => {
          if (result.status === 200) {
            this.notify({
              text: 'Backup period has been updated successfully!',
              variant: 'success',
            })
            // update the data without refreshing the page
            this.user.backup_start = this.backup.startDate
            this.user.backup_end = this.backup.endDate
          } else {
            this.notify({
              text: 'Backup period has not been updated!',
              variant: 'danger',
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.notify({
            text: 'Trial period has not been updated!',
            variant: 'danger',
          })
        })
    },
    addDiscount() {
      const payload = {
        uid: this.user.uid,
        amount: this.discount.amount,
        duration: this.discount.duration,
      }

      this.$store.dispatch('billing/addDiscount', payload)
        .then(() => {
          this.notify({
            text: 'Discount has been added successfully!',
            variant: 'success',
          })
          this.$emit('refreshUserInvoice', true)
        })
        .catch(err => {
          console.log(err)
          this.notify({
            text: 'Discount has not been added successfully!',
            variant: 'danger',
          })
        })
    },
    confirmEmail() {
      this.sendingConfirmEmail = true
      this.$store.dispatch('auth/activate', this.user.user_id).then(res => {
        this.$store.commit('users/UPDATE_USER', res)
        this.$emit('updateUser', res)
        this.notify({
          text: 'User Confirmed Successfully!',
          variant: 'success',
        })
        this.$refs.activationEmail.hide()
        this.sendingConfirmEmail = false
      })
    },
    resendActivationEmail() {
      this.sendingActivationEmail = true
      this.$store.dispatch('auth/resendActivationEmail', this.user.user_id).then(() => {
        this.notify({
          text: 'Email Activation Sent Successfully!',
          variant: 'success',
        })
        this.$refs.activationEmail.hide()
        this.sendingActivationEmail = false
      })
    },
    addReferral() {
      const payload = {
        user: this.referral.by.uid,
        type: this.referral.type,
        uid: this.user.uid,
      }

      this.$store.dispatch('users/addReferral', payload).then(() => {
        this.notify({
          text: 'Referral has been added successfully!',
          variant: 'success',
        })
      })
    },
    setIP() {
      const payload = {
        uid: this.user.uid,
        ipv4: this.ip.v4,
        ipv6: this.ip.v6,
      }

      this.$store.dispatch('users/setIP', payload).then(() => {
        this.notify({
          text: 'DNS records have been set.',
          variant: 'success',
        })
      })
    },
    sendExpireRequest() {
      const payload = {
        uid: this.user.uid,
        status: this.expireTagStatus,
      }

      this.$store.dispatch('users/expireTagRequest', payload).then(() => {
        this.notify({
          text: 'Expire Tag Request Sent.',
          variant: 'success',
        })
      })
    },
    generateRenewPFI() {
      this.sendingGenerateRenewPFI = true
      const payload = {
        uid: this.user.uid,
      }

      this.$store.dispatch('billing/generateRenewPFI', payload).then(() => {
        this.notify({
          text: 'Renew PFI Generated Successfully!',
          variant: 'success',
        })
        this.sendingGenerateRenewPFI = false
        this.$refs.proFormaModal.hide()
        this.$emit('refreshUserInvoice', true)
      })
    },
    generateRecurrentPFI() {
      this.sendingGenerateRecurrentPFI = true
      const payload = {
        uid: this.user.uid,
      }

      this.$store.dispatch('billing/generateRecurrentPFI', payload).then(() => {
        this.notify({
          text: 'Recurrent PFI Generated Successfully!',
          variant: 'success',
        })
        this.sendingGenerateRecurrentPFI = false
        this.$refs.proFormaModal.hide()
        this.$emit('refreshUserInvoice', true)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}
.modal .modal-header .close{
  margin: 2px
}
</style>
